import {LinkEntity, LinkFromLinkTo} from "../Models/Entities";
import LinkOperationsHelper, {
    updateLinkSublinkNodeStates
} from "../Views/MapComponents/Map/MapStateHandlerHelpers/LinkOperationsHelper";
import CreateConnectivityCommand from "../Views/MapComponents/ChangeTracker/ChangeTypes/CreateConnectivityCommand";
import UpdateConnectivityCommand from "../Views/MapComponents/ChangeTracker/ChangeTypes/UpdateConnectivityCommand";
import {MapController} from "../Views/MapComponents";

/**
 * Add connectivity for the given two links, update link state(s), and
 * emit onTrackAddConnectivity or onTrackUpdateConnectivity event.
 * @param startNodeLink The link containing the start node of the connectivity (not the first link in the path)
 * @param endNodeLink The link containing the end node of the connectivity (not the final link in the path)
 * @param map
 * @param link
 * @param isEditAction
 * @param lfltToBeRemoved if isEditAction is true, provide connectivity need to be removed.
 */
export function addConnectivity(startNodeLink: LinkEntity, endNodeLink: LinkEntity, map: MapController, 
                                link: LinkEntity, isEditAction?: boolean, 
    lfltToBeRemoved?: LinkFromLinkTo): boolean {
    
    const _startNodeLink = LinkOperationsHelper.copyLink(startNodeLink);
    const _endNodeLink = LinkOperationsHelper.copyLink(endNodeLink);

    // add connectivity
    const newLinkFrom = new LinkFromLinkTo({
        linkFrom: endNodeLink, linkFromId: endNodeLink.id, linkTo: startNodeLink, linkToId: startNodeLink.id,
    });
    newLinkFrom.id = newLinkFrom._clientId;
    startNodeLink.linkFroms.push(newLinkFrom);
    endNodeLink.linkTos.push(newLinkFrom);

    if (startNodeLink && endNodeLink && map) {
        updateLinkSublinkNodeStates(startNodeLink, map.getEventHandler());
        updateLinkSublinkNodeStates(endNodeLink, map.getEventHandler());
        // map.getEventHandler().emitPathEditedEvent();

        /**
         * Emit the event to render the connectivity graphic for the updated link
         */
        map.getEventHandler().emit('requestUpdate', link);
    }
    if (!isEditAction) {
        console.log('emit add connectivity event');
        map.getTracker().addChange(new CreateConnectivityCommand(endNodeLink.id, startNodeLink.id));
    } else if (isEditAction && lfltToBeRemoved) {
        console.log('emit update connectivity event');

        map.getTracker().addChange(new UpdateConnectivityCommand(lfltToBeRemoved.linkFromId, lfltToBeRemoved.linkToId, endNodeLink.id, startNodeLink.id));
    }

    return true;
}