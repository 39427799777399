import {
	AreaEntity,
	LinkEntity, LinkFromLinkTo, NodeEntity, SublinkEntity,
} from '../../../../Models/Entities';
import axios from 'axios';
import { SERVER_URL, SUBLINK_ID_MAX } from '../../../../Constants';
import Path, { IPathOptions } from '../MapObjects/Path/Path';
import alertToast from '../../../../Util/ToastifyUtils';
import _, { forEach } from 'lodash';
import MapStore, { IDrivingZoneUpdate } from '../MapStore';
import { LeafletCoordinates, PixiCoordinates, RealWorldCoordinates, realWorldCoordinates } from '../Helpers/Coordinates';
import { MapEventHandler, MapRenderer, Sublink } from 'Views/MapComponents';
import SubLink from '../MapObjects/SubLink/SubLink';
import geoJsonToPoints, { getJsonObject } from '../Helpers/GeoJSON';
import * as PIXI from 'pixi.js';
import { MapObjectType } from '../MapObjects/MapObject';
import MapController from '../MapController';
import { store } from '../../../../Models/Store';
import { getLinksToRecalculateDrivingZone } from '../Helpers/DrivingZone';
import { calcHeading, offsetAlongHeadingPixi, trc_caller } from '../Helpers/MapUtils';
import { LeafletMouseEvent } from 'leaflet';
import { ToolbarEvent } from 'Views/MapComponents/MapToolbar/Toolbar';
import * as uuid from 'uuid';
import { IWaypointRequestData } from './PathRequestHelper';
import { runInAction } from 'mobx';
import { nodetask } from 'Models/Enums';
import { LinkSegmentDirection, Waypoint } from '../MapStateHandlers/ClothoidStateHandler';
import MapValidator, { IAreaInfo } from '../MapValidators/MapValidator';

export enum HoverState {
	DEFAULT,
	HEADING_BACK,
	HEADING_FRONT,
	WAYPOINT,
}
export interface IRequestDrivingZonesParam {
	linksToUpdateJson: {}[];
	startEndLinksJson: {}[];
	exitPathIds: string[];
}

const _sublink = {
	drivingZone: {},
	nodess: { previousNode: {}, nextNode: {} },
};

export const linkReferencePath = {
	sublinkss: _sublink,
	linkTos: {},
	linkFroms: {},
	signalSetss: {},
};
export default class PathToolHelper {
////////////// START DRIVING ZONE CODE ////////////////

	public static areaInfoCache?: IAreaInfo;
	/**
	 * Initial render of driving zones
	 * @param pathObject
	 * @param renderer
	 */
	public static renderDrivingZones(path: Path, renderer: MapRenderer) {
		const sublinks = path.getChildren().filter(child => child.getType() === 'sublink');
		sublinks.forEach(obj => {
			const sublinkObject = obj as Sublink;
			const dz = sublinkObject.getDrivingZoneObject();
			const sublinkEntity = sublinkObject.getSublinkEntity();
			if (!!dz) {
				const errors = sublinkEntity.mapObjectErrorss.length > 0;
				if (errors) {
					dz.isError = true;
				}
				const warnings = sublinkEntity.mapObjectWarningss.length > 0;
				if (warnings) {
					dz.isWarning = true;
				}
				renderer.markObjectToRerender(dz.getId());
			}
		});
	}

	private static doNodeOperations(lookup: MapStore, linkEntity: LinkEntity) {
		const setPreviousNodeForSublink = (sublink: SublinkEntity) => {
			sublink.nodess.forEach(node => {
				if (node.previousNodeId) {
					const previousNode = lookup.getEntity(node.previousNodeId, NodeEntity);
					if (!!previousNode) {
						node.previousNode = previousNode;
					}
				}
				if (!!node.nextNode) {
					const nextNode = lookup.getEntity(node.nextNode.id ?? node.nextNode._clientId, NodeEntity);
					if (!!nextNode) {
						node.nextNode = nextNode;
					}
				}
			});
		};

		linkEntity.sublinkss.forEach(sublink => {
			setPreviousNodeForSublink(sublink);
			const node = lookup.getFirstNodeForSublink(sublink.id);

			if (node) {
				sublink.nodess = sublink.getNodes();
			}
		});
	};

	private static startEndLinks(lookup: MapStore, affectedLinks: LinkEntity[], activeLink?: LinkEntity) {
		return affectedLinks.reduce<LinkEntity[]>((links, linkEntity) => {
		// NOTE: affectedLinks can never be null if it reaches here

			linkEntity.linkFroms.forEach(linkFrom => {
				const previousLink = affectedLinks!.find(x => x.id === linkFrom.linkFromId);
				if (!previousLink) {
					const isActiveLink = linkFrom.linkFromId === activeLink?.id;
					const newLink = isActiveLink ? activeLink : lookup.getEntity(linkFrom.linkFromId, LinkEntity);
					if (!!newLink) {
						links.push(newLink);
					}
				}
			});
			linkEntity.linkTos.forEach(linkTo => {
				const nextLinks = affectedLinks!.find(x => x.id === linkTo.linkToId);
				if (!nextLinks) {
					const isActiveLink = linkTo.linkToId === activeLink?.id;
					const newLink = isActiveLink ? activeLink : lookup.getEntity(linkTo.linkToId, LinkEntity);
					if (!!newLink) {
						links.push(newLink);
					}
				}
			});

			return links;
		}, []);
	}

		/**
	 * Calculates and renders the driving zone for the given links or connectivity
	 * @param lookup
	 * @param linkEntities - Affected links
	 * @param connectivity - The connectivity entity - LinkFromLinkTo entity
	 * @param importVersionEntityId - Import version entity id
	 */
	public static getRecalculateDrivingZoneParams = (
		lookup: MapStore,
		activeLink: LinkEntity,
		affectedLinks: LinkEntity[],
		debugStr?: string,
	): IRequestDrivingZonesParam | undefined => {
		console.log(`getRecalculateDrivingZoneParams: via ${debugStr}`);
		// doNodeOperations should only be used in the context of calculateDrivingZone

		if (!affectedLinks || affectedLinks.length === 0) {
			return undefined;
		}

		let linksToUpdateJson: {}[] = [];
		 affectedLinks.forEach(link => {
			if (!activeLink || activeLink.id !== link.id) {
				this.doNodeOperations(lookup, link);
				linksToUpdateJson.push(link.toJSON(linkReferencePath));
			} else {
				console.log(`Skip doNodeOperations for activeLink ${link.id}`);
			}
			return;
		});

		// STARTENDLINKS
		// const startEndLinks: LinkEntity[] = []; 
		
		const startEndLinks = this.startEndLinks(lookup, affectedLinks, activeLink).filter(x => x.id !== activeLink.id);

		const startEndLinksJson = _.uniqBy(startEndLinks, x => x.id).map(link => {
			this.doNodeOperations(lookup, link);
			return link.toJSON(linkReferencePath);
		});

		const exitPathIds = this.getExitPathIds(affectedLinks);

		return {
			linksToUpdateJson: linksToUpdateJson, startEndLinksJson: startEndLinksJson, exitPathIds: exitPathIds,
		};
	}

	/**
	 * Used to render drivingzone previews (e.g. during any link modification where connectivity is involved)
	 * This method is used for both the previews (via afterPathUpdate) and to restore to original state (restoreLinks)
	 * 
	 * @param lookup 
	 * @param updatedLinks 
	 * @returns 
	 */
	public static reRenderTempDrivingZones = (lookup: MapStore, updatedLinks: LinkEntity[]) => {
		if (!updatedLinks || updatedLinks.length === 0) {
			return;
		}
		console.log('reRenderTempDrivingZones');
		const nodesToUpdate: NodeEntity[] = [];
		updatedLinks?.forEach(async updatedLink => {
			const linkObjectId = lookup.getMapObjectId(updatedLink.id, 'link');

			// Update the required information for each node
			updatedLink.sublinkss.forEach(sublink => sublink.nodess.forEach(node => {
				const existingNode = lookup.getEntity(node.id, NodeEntity);
				if (!existingNode) {
					return;
				}
				existingNode.bermLeft = node.bermLeft;
				existingNode.bermRight = node.bermRight;
				existingNode.bermLeftExtensionDistance = node.bermLeftExtensionDistance;
				existingNode.bermRightExtensionDistance = node.bermRightExtensionDistance;
				existingNode.curvature = node.curvature;
				existingNode.curveDirection = node.curveDirection;
				existingNode.interNodeDist = node.interNodeDist;
				nodesToUpdate.push(existingNode);
			}));

			const { renderer } = store;
			const linkObject = renderer.getObjectById(linkObjectId);
			const updatedPath = linkObject.getParent() as Path;
			if (updatedPath) {
				const addToDZUpdateEntry = false; // temp only
				this.updateSublinksAndDrivingZones(updatedPath, renderer, updatedLink.sublinkss, nodesToUpdate, addToDZUpdateEntry);
				renderer.rerender();
			}
		});
	}

	// /**
	//  * Calculates and renders the driving zone for the given links or connectivity
	//  * @param lookup
	//  * @param linkEntities - Affected links
	//  * @param connectivity - The connectivity entity - LinkFromLinkTo entity
	//  * @param importVersionEntityId - Import version entity id
	//  */
	// public static calculateDrivingZone = async (
	// 	lookup: MapStore,
	// 	importVersionEntityId: string,
	// 	linkEntities?: LinkEntity[],
	// 	connectivity?: LinkFromLinkTo,
	// 	debugStr?: string,
	// 	activeLink?: LinkEntity,
	// 	saveChanges? : boolean,
	// ) => {
	// 	console.log(`calculateDrivingZone: via ${debugStr} connectivity? ${!!connectivity} affectedLinks ${!!linkEntities ? linkEntities.length : ''}`);
	// 	// doNodeOperations should only be used in the context of calculateDrivingZone
	// 	const doNodeOperations = (linkEntity: LinkEntity) => {
	// 		const setPreviousNodeForSublink = (sublink: SublinkEntity) => {
	// 			sublink.nodess.forEach(node => {
	// 				if (node.previousNodeId) {
	// 					const previousNode = lookup.getEntity(node.previousNodeId, NodeEntity);
	// 					if (!!previousNode) {
	// 						node.previousNode = previousNode;
	// 					}
	// 				}
	// 				if (!!node.nextNode) {
	// 					const nextNode = lookup.getEntity(node.nextNode.id ?? node.nextNode._clientId, NodeEntity);
	// 					if (!!nextNode) {
	// 						node.nextNode = nextNode;
	// 					}
	// 				}
	// 			});
	// 		};
	//
	// 		linkEntity.sublinkss.forEach(sublink => {
	// 			setPreviousNodeForSublink(sublink);
	// 			const node = lookup.getFirstNodeForSublink(sublink.id);
	//
	// 			if (node) {
	// 				sublink.nodess = sublink.getNodes();
	// 			}
	// 		});
	// 	};
	//
	// 	let affectedLinks = linkEntities;
	//
	// 	if (!!connectivity) {
	// 		affectedLinks = getLinksToRecalculateDrivingZone(connectivity, activeLink);
	// 	} else {
	// 		affectedLinks = _.uniqBy(affectedLinks, x => x.id);
	// 	}
	//
	// 	if (!affectedLinks || affectedLinks.length === 0) {
	// 		return;
	// 	}
	//
	// 	const linksToUpdateJson = affectedLinks.map(link => {
	// 		if (!activeLink || activeLink.id !== link.id) {
	// 			doNodeOperations(link);
	// 		} else {
	// 			console.log(`Skip doNodeOperations for activeLink ${link.id}`);
	// 		}
	// 		return link.toJSON(linkReferencePath);
	// 	});
	//
	// 	//STARTENDLINKS
	// 	const startEndLinks = affectedLinks.reduce<LinkEntity[]>((links, linkEntity) => {
	// 		// NOTE: affectedLinks can never be null if it reaches here
	//
	// 		linkEntity.linkFroms.forEach(linkFrom => {
	// 			const previousLink = affectedLinks!.find(x => x.id === linkFrom.linkFromId);
	// 			if (!previousLink) {
	// 				const isActiveLink = linkFrom.linkFromId === activeLink?.id;
	// 				const newLink = isActiveLink ? activeLink : lookup.getEntity(linkFrom.linkFromId, LinkEntity);
	// 				if (!!newLink) {
	// 					links.push(newLink);
	// 				}
	// 			}
	// 		});
	// 		linkEntity.linkTos.forEach(linkTo => {
	// 			const nextLinks = affectedLinks!.find(x => x.id === linkTo.linkToId);
	// 			if (!nextLinks) {
	// 				const isActiveLink = linkTo.linkToId === activeLink?.id;
	// 				const newLink = isActiveLink ? activeLink : lookup.getEntity(linkTo.linkToId, LinkEntity);
	// 				if (!!newLink) {
	// 					links.push(newLink);
	// 				}
	// 			}
	// 		});
	//
	// 		return links;
	// 	}, []);
	// 	// const startEndLinks: LinkEntity[] = [];
	// 	// debugger;
	//
	// 	const startEndLinksJson = _.uniqBy(startEndLinks, x => x.id).map(link => {
	// 		doNodeOperations(link);
	// 		return link.toJSON(linkReferencePath);
	// 	});
	//
	// 	const exitPathIds = this.getExitPathIds(affectedLinks);
	// 	const entryPathIds = this.getEntryPathIds(affectedLinks);
	// 	await this.updateDrivingZoneRequest(lookup, linksToUpdateJson, startEndLinksJson, importVersionEntityId, entryPathIds, exitPathIds, saveChanges)
	// }

	public static getEntryPathIds = (links: LinkEntity[]) => {
		const entryPathIds: string[] = [];
		links.forEach(link => {
			const actualStart = link.firstNode();
			const actualEnd = link.lastNode();
			const controller = store.mapController;
			if (!!actualStart && !!actualEnd && !!controller) {
				const entryPathArea = PathToolHelper.isExitPath(realWorldCoordinates(actualStart.northing, actualStart.easting),
					realWorldCoordinates(actualEnd.northing, actualEnd.easting), controller);
				const isExitPath = !!entryPathArea;
				if (isExitPath) {
					entryPathIds.push(link.id);
				}
			}
		});
		return entryPathIds;
	};

	public static getExitPathIds(links: LinkEntity[]) {
		const exitPathIds: string[] = [];
		links.forEach(link => {
			const actualStart = link.firstNode();
			const actualEnd = link.lastNode();
			const controller = store.mapController;
			if (!!actualStart && !!actualEnd && !!controller) {
				const exitPathArea = PathToolHelper.isExitPath(realWorldCoordinates(actualStart.northing, actualStart.easting),
					realWorldCoordinates(actualEnd.northing, actualEnd.easting), controller);
				const isExitPath = !!exitPathArea;
				if (isExitPath) {
					exitPathIds.push(link.id);
				}
			}
		});
		return exitPathIds;
	}

	// private static updateDrivingZoneRequest = async (lookup: MapStore, linksToUpdateJson: any, startEndLinksJson: any, importVersionEntityId: string, entryPathIds: string[], exitPathIds: string[], saveChanges? : boolean) => {
	// 	console.time("updateDrivingZoneRequest");
	// 	try {
	// 		const response = await axios.post(`${SERVER_URL}/api/entity/LinkEntity/recalculateDrivingZone`, {
	// 			linkEntities: linksToUpdateJson,
	// 			startEndLinks: startEndLinksJson,
	// 			importVersionId: importVersionEntityId,
	// 			exitPathIds: exitPathIds,
	// 			entryPathIds: entryPathIds,
	// 		});
	// 		return this.reRenderUpdatedLinks(lookup, response.data, saveChanges);
	// 	} catch(errorMessage) {
	// 		console.error(errorMessage);
	// 		return null;
	// 	};
	// }

	/**
	 * Rerender the updated links
	 * @param stateHandler
	 * @param updatedLinks
	 */
	private static reRenderUpdatedLinks = async(lookup: MapStore, updatedLinks: LinkEntity[], saveChanges? : boolean) => {
		trc_caller('start');
		if (!updatedLinks) {
			alertToast('server returned null', 'error');
			return;
		}
		const nodesToUpdate: NodeEntity[] = [];
		updatedLinks?.forEach(async updatedLink => {
			const linkObjectId = lookup.getMapObjectId(updatedLink.id, 'link');

			// Update the required information for each node
			updatedLink.sublinkss.forEach(sublink => sublink.nodess.forEach(node => {
				const existingNode = lookup.getEntity(node.id, NodeEntity);
				if (!existingNode) {
					return;
				}
				existingNode.bermLeft = node.bermLeft;
				existingNode.bermRight = node.bermRight;
				existingNode.bermLeftExtensionDistance = node.bermLeftExtensionDistance;
				existingNode.bermRightExtensionDistance = node.bermRightExtensionDistance;
				existingNode.curvature = node.curvature;
				existingNode.curveDirection = node.curveDirection;
				existingNode.interNodeDist = node.interNodeDist;
				nodesToUpdate.push(existingNode);
			}));

			const { renderer } = store;
			const linkObject = renderer.getObjectById(linkObjectId);
			if (!linkObject) {
				// debugger;
				return;
			}
			const updatedPath = linkObject.getParent() as Path;
			if (updatedPath) {
				const addToDZUpdateEntry = true;
				await this.updateSublinksAndDrivingZones(updatedPath, renderer, updatedLink.sublinkss, nodesToUpdate, addToDZUpdateEntry, saveChanges);
				renderer.rerender();
			}
		});
	}

	/**
	 * Update sublinks and drivingzones for updated links and save
	 * @param pathObject
	 * @param renderer
	 * @param updatedSublinkEntities
	 */
	 private static async updateSublinksAndDrivingZones(
		path: Path,
		renderer: MapRenderer,
		updatedSublinkEntities: SublinkEntity[],
		nodesToUpdate: NodeEntity[],
		addToDZUpdateEntry: boolean = true,
		saveChanges: boolean = true,
	) {
		const sublinks = path.getChildren().filter(child => child.getType() === 'sublink');
		const lookup = renderer.getController().getMapLookup();
		const toSave: IDrivingZoneUpdate[] = [];
		sublinks.forEach(obj => {
			const sublinkObject = obj as SubLink;
			let dz = sublinkObject.getDrivingZoneObject();
			let updatedSublinkEntity = updatedSublinkEntities?.find(x => x.id === sublinkObject.sublinkEntity.id);
			if (!!updatedSublinkEntity) {
				// weird type conversion due to drivingZone being returned from server as GeoJSON object when it should be a string
				const isDZString = typeof(updatedSublinkEntity.drivingZone) === 'string';
				// const updatedDrivingzoneGeoJSON: object = isDZString ? JSON.parse(updatedSublinkEntity.drivingZone) : updatedSublinkEntity.drivingZone; // updatedSublinkEntity.drivingZone as any as object;
				const updatedDrivingzoneGeoJSON: object = getJsonObject(updatedSublinkEntity.drivingZone);
				const originalSublink = lookup.getEntity(updatedSublinkEntity.id, SublinkEntity);
				if (!!originalSublink) {
					const drivingZone = isDZString ? updatedSublinkEntity.drivingZone : JSON.stringify(updatedSublinkEntity.drivingZone);
					if (drivingZone !== 'null') {
						// 1. Update the sublink entity currently on the map (as opposed to creating a new one)
						originalSublink.drivingZone = drivingZone;
						const nodes = nodesToUpdate.filter(n => originalSublink.nodess.some(x => x.id === n.id));
						// 2. Driving zone updates to be saved on backend
						toSave.push({
							sublinkId: originalSublink.id,
							drivingZone: originalSublink.drivingZone,
							nodes: nodes
						});
						// 3. Update points on the map object
						const updatedDZPoints = geoJsonToPoints(updatedDrivingzoneGeoJSON, renderer) as PIXI.Point[];
						if (!!dz) {
							dz.setEntity(updatedDZPoints);
						} else {
							dz = sublinkObject.createDrivingZoneObject();
						}
						renderer.markObjectToRerender(dz.getId());
					} else {
						console.log('updateSublinksAndDrivingZones: Driving zone is null!! Not Saving!');
					}
					if (!!updatedSublinkEntity.linkId) {
						// For debug purposes
						const id = lookup.getEntity(updatedSublinkEntity!.linkId, LinkEntity)?.linkId;
						console.log(`updateSublinksAndDrivingZones: Update link ${id}`);
					}
				} else {
					console.error('original sublink not found');
				}
			} else {
				// TODO: look into this
				console.log('undefined SublinkEntity on object');
			}
		});
		if (addToDZUpdateEntry) {
			// 4. Save accumulated driving zone updates to server
			toSave.forEach(entry => {
				lookup.addDrivingZoneUpdateEntry(entry);
			});
		}
	}

	////////////// END DRIVING ZONE CODE ////////////////

	///////////// START GENERAL HANDLER CODE - SHARED BETWEEN REQ HANDLER, CREATE AND EDIT ///////////

	/**
	 * Retrieves the next available id for
	 * link/sublink/node
	 * @param idCounter
	 * @param existingIds
	 * @param maxBound
	 */
	public static getNextAvailableId = (idCounter: number, existingIds: number[], maxBound: number): number | undefined => {
		let tempIdCounter = idCounter;
		let idFound: boolean = false;

		for (tempIdCounter; tempIdCounter <= maxBound; tempIdCounter++) {
			if (existingIds.every(id => id !== tempIdCounter)) {
				idFound = true;
				break;
			}
		}

		if (idFound) {
			return tempIdCounter;
		}
		return undefined;
	};

	// TODO: put some of these in HELPER class that's instantiated in onInit
	public static calcWaypointHeading(node1: NodeEntity, node2: NodeEntity) {
		return calcHeading(
			{ northing: node1.northing, easting: node1.easting },
			{ northing: node2.northing, easting: node2.easting },
		);
	}

	/**
	 * Only works on links that are already ordered. e.g. orderSublinksAndNodesByPreviousIds has already been run
	 * @param link
	 * @param lookup
	 */
	public static calcStartWaypointHeading(link: LinkEntity, lookup: MapStore): number | undefined {
		const firstNode = link.firstNode()!;
		const secondNode = firstNode.getNextNode();
		return !!secondNode ? this.calcWaypointHeading(firstNode, secondNode) : undefined;
	}

	public static calcEndWaypointHeading(link: LinkEntity, lookup: MapStore) {
		const lastNode = link.lastNode()!;
		const secondLastNode = lastNode.getPreviousNode();
		return !!secondLastNode ? this.calcWaypointHeading(secondLastNode, lastNode) : undefined;
	}

	public static isWaypointValid(node: NodeEntity) {
		const { northing, easting, heading }  = node;
		return (heading !== undefined && northing !== undefined && easting !== undefined);
	}

	public static areWaypointsEqual(node1: NodeEntity, node2: NodeEntity): boolean {
		try {
			return node1.northing.toFixed(2) === node2.northing.toFixed(2)
				&& node1.easting.toFixed(2) === node2.easting.toFixed(2);
		} catch(e) {
			// should not reach here (but sometimes does) execution should continue as normal.
			// TODO: see why error "node2.northing.toFixed is not a function" occasionally occurs
			console.error(`node1: ${node1.northing} ${node1.easting} node2: ${node2.northing} ${node2.easting}`)
		};
		return false;
	}

	// Assumes nodes are in order
	public static getAllNodesOfLink(link: LinkEntity | undefined) {
		if (!!link) {
			return link.sublinkss.reduce<NodeEntity[]>((list, x) => {
				x.nodess.forEach(y => list.push(y));
				return list;
			}, []);
		}
		return [];
	}

	public static getClothoidDirectionProperty(link: LinkEntity) {
		let hasPostive = false;
		let hasNegative = false;
		const speedArr: number[] = [];
		const isMixed = this.getAllNodesOfLink(link).some(x => {
			if (!!x.speed) {
				speedArr.push(x.speed);
				if (x.speed > 0.1) {
					hasPostive = true;
				} else if (x.speed < -0.1) {
					hasNegative = true;
				}
			}
			return hasPostive === true && hasNegative === true;
		});
		if (isMixed) {
			return 'Mixed';
		} else if (hasPostive) {
			return 'forward';
		} else if (hasNegative) {
			return 'reverse';
		} else {
			console.warn('failed to calculate direction. using forward by default');
			return 'forward'
		}
	}

	public static findPreviousNode(linkEntity: LinkEntity, nodeEntity: NodeEntity): NodeEntity | undefined {
		// Handles two cases: 1. original nodes being displayed 2. Newly fetched nodes being displayed.
		let prevNode = nodeEntity.getPreviousNode();
		if (!prevNode) {
			const allNodes = this.getAllNodesOfLink(linkEntity);
			const targetIndex = allNodes.findIndex(n => n.id === nodeEntity.id);
			if (targetIndex > -1) {
				const currentNode = allNodes[targetIndex];
				prevNode = allNodes[targetIndex - 1];
				// sanity check
				if (currentNode.previousNodeId !== prevNode.id) {
					console.log(`getPreviousNode: Nodes out of order`, 'warn');
				}
			} else {
				console.log("getPreviousNode: Unable to find previous node");
			}

		}
		return prevNode;
	}

	public static getDirectionFromSpeed(speed?: number): LinkSegmentDirection {
		return (!speed || speed >= 0) ? "forward" : "reverse";
	}

	/**
	 * Create and render new path from the newLinkEntity
	 * If there's an existing path, it's removed first
	 */
	public static createAndRenderPath(renderer: MapRenderer, waypoints: Waypoint[], newLinkEntity: LinkEntity, prevPathObjId?: string) {
		//const renderer = this.getRenderer();
		let oldId = '0';
		if (prevPathObjId) {
			oldId = prevPathObjId
			renderer.removeObject(prevPathObjId);
		}
		const opts: IPathOptions = {
			isSelected: true,
			forceBuild: true,
			allLookup: false,
			reverseWaypointIds: waypoints.filter(x => !x.isReadOnly && !!x.isReverse).map(x => x.node.id),
		};
		const path = new Path(newLinkEntity, renderer, undefined, opts);
		renderer.addObject(path);
		// this.trc1(`createAndRenderPath: Added path ${path.getId()} Removed: ${oldId}`);
		PathToolHelper.renderDrivingZones(path, renderer);

		renderer.rerender();
		return path;
	}



	public static checkHoverState(renderer: MapRenderer, event: LeafletMouseEvent, nodeEntity: NodeEntity): HoverState {
		if (!!nodeEntity.northing && nodeEntity.heading !== undefined) {
			// Take the point, and extrap according to heading angle
			// To do this, calculate point along distance
			const offset = offsetAlongHeadingPixi(50, nodeEntity.heading);
			const nodePoint = renderer.project({ northing: nodeEntity.northing, easting: nodeEntity.easting });
			const circleHeading1 = new PIXI.Circle(nodePoint.x + offset.x, nodePoint.y + offset.y, 10);
			const circleHeading2 = new PIXI.Circle(nodePoint.x - offset.x, nodePoint.y - offset.y, 10);
			const circleNode = new PIXI.Circle(nodePoint.x, nodePoint.y, 10);
			const { x, y } = renderer.project(event.latlng);
			if (circleHeading1.contains(x, y)) {
				return HoverState.HEADING_FRONT;
			}
			if (circleHeading2.contains(x, y)) {
				return HoverState.HEADING_BACK;
			}
			if (circleNode.contains(x, y)) {
				return HoverState.WAYPOINT;
			}
		}
		return HoverState.DEFAULT;
	}

	public static isForwardDirection(direction: string | LinkSegmentDirection) {
		return direction !== 'reverse';
	}

	/**
	 * Method used to verify correct tool is selected. Handles edge cases.
	 * @param expectedTool 
	 */
	public static verifyAndUpdateTool(controller: MapController, expectedTool: ToolbarEvent) {
		const tool = controller.getSelectedToolType();
		if (expectedTool !== tool) {
			console.warn(`Got ${tool}. Expected ${expectedTool} tool. Setting to ${expectedTool}...`);
			controller.getEventHandler().emit('setActiveTool', expectedTool);
		}
	}

	// use dot product to determine if in front of behind
	public static isBehind(a: RealWorldCoordinates, b: RealWorldCoordinates, c: RealWorldCoordinates): boolean {
		// eslint-disable-next-line max-len
		return ((b.easting - a.easting) * (c.northing - a.northing) - (b.northing - a.northing) * (c.easting - a.easting)) > 0;
	}

	// Used in processLinkData PathRequestHelper
	private static updateNodeProps(from: NodeEntity, to: NodeEntity) {
		to.nodeId = from.nodeId;
		to.linkIdNumber = from.linkIdNumber;
		to.speed = from.speed;
		to.gradient = from.gradient;
		to.sublinkIdNumber = from.sublinkIdNumber;
		to.interNodeDist = from.interNodeDist;
		to.curvature = from.curvature;
		to.up = from.up;
		to.previousNode = from.previousNode;
		to.previousNodeId = from.previousNodeId;
		to.nextNode = from.nextNode;
		to.bermLeftExtensionDistance = from.bermLeftExtensionDistance;
		to.bermRightExtensionDistance = from.bermRightExtensionDistance;
		to.bermLeft = from.bermLeft;
		to.bermRight = from.bermRight;
		to.boundaryOfLink = from.boundaryOfLink;
		to.boundaryOfSublink = from.boundaryOfSublink;
		to.curveDirection = from.curveDirection;
		to.isImported = from.isImported;

		if (to.task !== 'HAULING') {
			to.speed = 0;
		}
	}

	// 
	/**
	 * Processing of link data returned from server. Used in PathRequestHelper
	 * @param link new link entity constructed with data from server
	 * @param startWaypoint original start waypoint (data contained before req)
	 * @param endWaypoint original end waypoint (data contained before req)
	 * @param waypoints 
	 */
	public static processLinkData(link: LinkEntity, startWaypoint: NodeEntity, endWaypoint: NodeEntity, waypoints: IWaypointRequestData[]) {
		runInAction(() => {
			// this.isClothoidValid = true;

			// These ID fields need to be filled so that start/end nodes can be correctly identified
			link.sublinkss.forEach((sl, sublinkIndex, sublinkArray) => {
				// IMPORTANT/TODO: references are set here simply so NodeGraphic has enough information
				// to distinguish start/end nodes. It would be better to add them to some kind of temp lookup
				const prevSublinkIndex = sublinkIndex - 1;
				const previousSublink = prevSublinkIndex > -1 ? sublinkArray[prevSublinkIndex] : undefined;
				if (!!sl.previousSublinkId && !previousSublink) {
					console.error('no previous sublink');
				}
				if (sl.previousSublinkId && !!previousSublink) {
					sl.previousSublink = previousSublink;
					sl.previousSublinkId = previousSublink.id ?? previousSublink._clientId;
				}
				// (sl.previousSublink as any) = undefined;
				// sl.nextSublink = undefined;
				// Setting the id to _clientid if the id is NIL (all 0s)
				if (sl.id === uuid.NIL) {
					sl.id = sl._clientId;
				}
				if (sl.nextSublink) {
					if (link.sublinkss[sublinkIndex + 1].id === uuid.NIL) {
						link.sublinkss[sublinkIndex + 1].id = link.sublinkss[sublinkIndex + 1]._clientId;
					}
					sl.nextSublink.id = link.sublinkss[sublinkIndex + 1].id;
				}
				sl.nodess.forEach((n, nodeIndex, nodeArray) => {
					const prevNodeIndex = nodeIndex - 1;
					const previousNode = prevNodeIndex > -1 ? nodeArray[prevNodeIndex] : undefined;

					if (!!n.previousNodeId && !previousNode) {
						console.error('no prev node');
					}
					if (!!n.previousNodeId && !!previousNode) {
						n.previousNode = previousNode;
						n.previousNodeId = previousNode.id ?? previousNode._clientId;
					}
					// (n.previousNode as any) = undefined;
					// n.nextNode = undefined;
					if (n.id === uuid.NIL) {
						n.id = n._clientId;
					}

					// IMPORTANT: update returned nodes with clientside info (if necessary, this could be moved to the serverside)
					const existingWaitpoint = waypoints.find(x => x.id == n.id);
					if (!!existingWaitpoint) {
						if (!n.heading) {
							//this.trc(`Re-adding heading value $(${existingWaitpoint.heading}) and task ${existingWaitpoint.task} for waypoint: ${existingWaitpoint.id} `);
							// this.trc(`Original values from server: ${n.heading} ${n.task} ${n.id}`)
							n.heading = existingWaitpoint.heading;
							// TODO: if the task is set incorrectly, this might need updating
							if (!n.task) {
								const updateTask: nodetask = existingWaitpoint.isReverse ? 'REVERSEPOINT' : 'HAULING';
								console.warn(`Found undefined task!! Setting as ${updateTask}, 'warn`);
								n.task = updateTask;
							}
							n.isMidWaypoint = existingWaitpoint.isMidWaypoint;
							// n.task = existingWaitpoint.isReverse ? 'REVERSEPOINT' : 'HAULING';
						}
					}

					if (n.nextNode) {
						const nextNodeIndex = nodeIndex + 1;
						if (nextNodeIndex >= nodeArray.length) {
							console.error("invalid next node");
						}
						const nextNode = nodeArray[nextNodeIndex];
						n.nextNode = nextNode;
						// Setting the id to _clientid if the id is NIL (all 0s)
						if (nextNode.id === uuid.NIL) {
							nextNode.id = nextNode._clientId;
						}
						// The next node entity in the current node entity is different than the actual next node entity in the array.
						// Hence, setting the id to appropriate node entity
						n.nextNode.id = nextNode.id;
					}
					n.sublink = sl;
					n.sublinkId = sl.id; // TODO: verify
				});
			});

			// IMPORTANT: assumes nodes are in order
			// TODO: investigate why the line below fails, for now just update
			// the relevent props manually via updateNodeProps
			// this.startWaypoint = new NodeEntity({...link.sublinkss[0].nodess[0]});
			// Update props manually because heading graphic fails when creating new entity
			const firstNodeId = link.sublinkss[0].nodess[0].id;

			this.updateNodeProps(link.sublinkss[0].nodess[0], startWaypoint);
			link.sublinkss[0].nodess[0] = startWaypoint;
			const lastSublink = link.sublinkss[link.sublinkss.length - 1];
			const endWaypointIndex = lastSublink.nodess.length - 1;

			const lastNodeId = lastSublink.nodess[endWaypointIndex].id;

			this.updateNodeProps(lastSublink.nodess[endWaypointIndex], endWaypoint);
			lastSublink.nodess[endWaypointIndex] = endWaypoint;
			// important!
			// this.clothoidLinkEntity = link;
			// the above is already in result.link
		});
	}

	public static isExitPath(realStartCoords: RealWorldCoordinates, realEndCoords: RealWorldCoordinates, controller: MapController) {
		const renderer =  controller.getMapRenderer();
		const startCoords = renderer.project(realStartCoords);
		const endCoords = renderer.project(realEndCoords);

		if (!!this.areaInfoCache) {
			const { polygon } = this.areaInfoCache;
			if ((polygon.contains(startCoords.x, endCoords.y) && !polygon.contains(endCoords.x, endCoords.y)) ) {
				return this.areaInfoCache.area;
			}
		}
		const areaInfo = MapValidator.getAreaAtCoords(startCoords, controller);
		if (!!areaInfo) {
			this.areaInfoCache = areaInfo;
			return areaInfo.polygon.contains(endCoords.x, endCoords.y) ? undefined : areaInfo.area;
		}
		return this.areaInfoCache = undefined;
	}

	public static isEntryPath(realStartCoords: RealWorldCoordinates, realEndCoords: RealWorldCoordinates, controller: MapController): AreaEntity | undefined {
		const renderer =  controller.getMapRenderer();
		const startCoords = renderer.project(realStartCoords);
		const endCoords = renderer.project(realEndCoords);

		if (!!this.areaInfoCache) {
			const { polygon } = this.areaInfoCache;
			// If it contains the start node, but not the end node
			if (!polygon.contains(startCoords.x, endCoords.y) && polygon.contains(endCoords.x, endCoords.y)) {
				return this.areaInfoCache.area;
			}
		}
		const areaInfo = MapValidator.getAreaAtCoords(endCoords, controller);
		if (!!areaInfo) {
			this.areaInfoCache = areaInfo;
			return areaInfo.polygon.contains(startCoords.x, startCoords.y) ? undefined : areaInfo.area;
		}
		return this.areaInfoCache = undefined;
	}
}
