import MapStateHandler from './MapStateHandler';
import { MapType } from '../MapController';
import { LeafletMouseEvent } from 'leaflet';
import MapObject from '../MapObjects/MapObject';
import { DrivingZone, Link, MapLookup, MapRenderer, NodeGraphic, Sublink } from 'Views/MapComponents';
import { defaultContextMenuOptions } from 'Views/MapComponents/RightClickContextMenu';
import LinkOperationsHelper from '../MapStateHandlerHelpers/LinkOperationsHelper';
import { Model } from '../../../../Models/Model';
import { NodeEntity, SublinkEntity } from 'Models/Entities';
import PathValidator from '../MapValidators/PathValidator';
import { IPropertiesPanelParams } from 'Views/MapComponents/MapProperties/PropertiesSidePanel';
import { setCustomTag } from '../Helpers/MapUtils';

interface ISelectToolHandler {
	mapObject?: MapObject<any>;
	unselectHandler?: () => void;
}

export default class SelectToolHandler extends MapStateHandler<ISelectToolHandler> {
	onInit(initialState?: ISelectToolHandler) {
		this.handleMapObjectClicked(initialState?.mapObject, initialState?.unselectHandler);
	}

	onClick(event: LeafletMouseEvent) {
		const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
		this.handleMapObjectClicked(mapObject);
	}

	onRightClick(event: LeafletMouseEvent) {
		const mapObject = this.getController().getMapObjectAtCoordinates(event.latlng);
		if (!!mapObject && mapObject instanceof Link) {
			this.handleMapObjectClicked(mapObject);
			LinkOperationsHelper.linkRightClickMenu(event, this.getEventHandler(), mapObject);
		} else {
			this.getEventHandler().emit('onCustomContextMenu', defaultContextMenuOptions, event);
		}
	}

	onEscapePressed(event: KeyboardEvent) {
		this.getEventHandler().setActiveTool('selector');
		this.showMapProperties();
	}

	onRequestUpdate(entity?: Model) {
		if (!!entity) {
			const lookup = this.getLookup();
			const renderer = this.getRenderer();

			switch (entity.getModelDisplayName()) {
				case 'Node':
					SelectToolHandler.updateNodeMapObject(entity as NodeEntity, lookup, renderer);
					break;
			}
		}
	}

	onMove(event: LeafletMouseEvent) {
	}

	public static async updateNodeMapObject(node: NodeEntity, lookup: MapLookup, renderer: MapRenderer) {
		const nodeMapObjectId = lookup.getMapObjectId(node.id, 'node');
		const nodeMapObject = renderer.getObjectById(nodeMapObjectId) as NodeGraphic;
		if (!nodeMapObject) {
			return;
		}

		renderer.markObjectToRerender(nodeMapObject.getId());
		renderer.rerender();
	}

	/**
	 * If user clicks on map object show corresponding properties panel
	 * When no particular object is selected display map properties
	 * @param mapObject
	 * @param unselectHandler
	 */
	private handleMapObjectClicked(mapObject?: MapObject<any>, unselectHandler?: () => void) {
		const isCheckPathInterference = this.getLookup().isCheckPathInterference;
		const isSelectASublinkInterference = this.getLookup().isSelectASublinkInterference;
		const params: IPropertiesPanelParams | undefined  = !!unselectHandler ? { onUnselect: unselectHandler } : undefined;
		if (!!mapObject) {
			setCustomTag('map-interface', 'select-a-map-object');
			let entity = mapObject.getEntity();

			this.getEventHandler()
				.emit(
					'onPropertiesPanel',
					mapObject.getType() as MapType,
					mapObject,
					params,
				);

			// De-select a sublink
			if (isSelectASublinkInterference && mapObject.getType() !== 'sublink') {
				this.getLookup().isSelectASublinkInterference = false;
				// If Path Interference is checked, show all sublink zone interference on the map
				if (isCheckPathInterference) {
					this.showHidePathInterference(true);
				}
			}

			console.log('select tool', mapObject.getType());

			switch (mapObject.getType()) {
				case 'bay':
					this.getEventHandler().setMapEventState('edit_bay', { mapObject });
					break;
				case 'area':
					this.getEventHandler().setMapEventState('edit_area', { mapObject });
					break;
				case 'link':
					this.getEventHandler().setMapEventState('select_confirmed_path', mapObject);
					entity = (mapObject as Link).getLinkEntity();
					break;
				case 'sublink':
					this.getLookup().isSelectASublinkInterference = true;
					// If Path Interference is checked and a sublink is selected, hide all sublink zone interference on the map
					if (isCheckPathInterference) {
						this.showHidePathInterference(false);
					}				
					entity = (mapObject as Sublink).getSublinkEntity();
					this.setSublinkInterference(entity);
					break;
			}

			console.log(`${mapObject.getType()} EntityId: ${entity.id}`);

			// 'unselectHandler' is undefined if the object is selected from the map
			if (!unselectHandler) {
				this.getEventHandler().emit('onMapObjectSelectedInMap', entity, mapObject.getType());
			}
		} else {
			// no object selected
			if (isSelectASublinkInterference) {
				this.getLookup().isSelectASublinkInterference = false;
				// If Path Interference is checked, show all sublink zone interference on the map
				if (isCheckPathInterference) {
					this.showHidePathInterference(true);
				}
			}
			console.log(`handleMapObjectClicked: NO OBJECT`);
			this.getEventHandler().emit('onMapObjectSelectedInMap', undefined);
			// setting TRUE will force properties to display
			this.showMapProperties(true);
		}
	}

	/**
	 * Map Properties are typically shown when selector tool is active but no object is selected
	 */
	private showMapProperties(isForceShow?: boolean) {
		const eventHandler = this.getEventHandler();
		const isToSelector = eventHandler.getToSelector();
		let isShow = false;
		if (eventHandler.getToSelector()) {
			console.log(`showMapProperties: isToSelector = ${isToSelector} Clearing isToSelector`);
			// TODO: this logic should be in handleMapObjectClicked
			eventHandler.setToSelector(false);
			if (!!isForceShow) {
				console.log(`showMapProperties: Forcing display of properties panel`);
				isShow = true;
			}
		} else {
			isShow = true;
		}
		if (isShow) {
			console.log(`showMapProperties: isToSelector = ${isToSelector} Emitting onMapObjectSelected`);
			this.getEventHandler().emit('onPropertiesPanel', 'map');
		}
	}

	/**
	 * Show or hide all sublink zone interference on the map instead of validating again.
	 */
	private showHidePathInterference(isShown: boolean) {
		const interferedDZIds = this.getLookup().getInterferePathDrivingZones();

		interferedDZIds.forEach(id => {
			const dz = this.getRenderer().getObjectById(id) as DrivingZone;
			if (!!dz) {
				dz.isInterference = isShown;
				this.getRenderer().markObjectToRerender(dz.getId());
			} else {
				console.warn('dz not exists >> showHidePathInterference');
			}
		});

		this.getRenderer().rerender();
	}

	private setSublinkInterference(sublinkEntity: SublinkEntity) {
		const checkPathInterference = this.getLookup().isCheckPathInterference;
		const selectASublinkInterference = this.getLookup().isSelectASublinkInterference;
		// TODO: shouldn't create pathvalidator. change to static method
		const pathValidator = new PathValidator(this.getController());
		const objIds: string[] = [];
		if (selectASublinkInterference && checkPathInterference) {
			pathValidator.validatePathInterference(sublinkEntity, objIds, true);
		}

		this.getRenderer().rerender();
	}

	dispose() {
		const { isSelectASublinkInterference, isCheckPathInterference } = this.getLookup();
		if (isSelectASublinkInterference) {
			this.getLookup().isSelectASublinkInterference = false;
			// If Path Interference is checked, show all sublink zone interference on the map
			if (isCheckPathInterference) {
				this.showHidePathInterference(true);
			}
		}
	}
}
