/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsLinkEntity from 'Models/Security/Acl/VisitorsLinkEntity';
import MineUserLinkEntity from 'Models/Security/Acl/MineUserLinkEntity';
import HitachiAdminLinkEntity from 'Models/Security/Acl/HitachiAdminLinkEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import {
	LinkFromLinkTo,
	MapObjectErrorsEntity,
	MapObjectWarningsEntity,
	NodeEntity,
	SignalSetEntity,
} from 'Models/Entities';
import { store } from '../Store';
import SublinkEntity from './SublinkEntity';
import { mapObjectState } from '../Enums';
import TurnSignalHelper from 'Views/MapComponents/Map/MapStateHandlerHelpers/TurnSignalHelper';
import * as uuid from 'uuid';
// % protected region % [Add any further imports here] end

export interface ILinkEntityAttributes extends IModelAttributes {
	linkId: number;
	signalCount: number;
	maxSpeed: number;
	constantSpeed: number;
	isImported: boolean;
	state: Enums.mapObjectState;
	hookToAreaEntry: boolean;
	hookToAreaExit: boolean;
	isDefaultSpeed: boolean;
	startSublink: string;

	importVersionId?: string;
	importVersion?: Models.ImportVersionEntity | Models.IImportVersionEntityAttributes;
	segmentId?: string;
	segment?: Models.SegmentEntity | Models.ISegmentEntityAttributes;
	mapObjectErrorss: Array<
		| Models.MapObjectErrorsEntity
		| Models.IMapObjectErrorsEntityAttributes
	>;
	mapObjectWarningss: Array<
		| Models.MapObjectWarningsEntity
		| Models.IMapObjectWarningsEntityAttributes
	>;
	signalSetss: Array<
		| Models.SignalSetEntity
		| Models.ISignalSetEntityAttributes
	>;
	sublinkss: Array<
		| Models.SublinkEntity
		| Models.ISublinkEntityAttributes
	>;
	linkTos: Array<
		| Models.LinkFromLinkTo
		| Models.ILinkFromLinkToAttributes
	>;
	linkFroms: Array<
		| Models.LinkFromLinkTo
		| Models.ILinkFromLinkToAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('LinkEntity', 'Link')
// % protected region % [Customise your entity metadata here] end
export default class LinkEntity extends Model
	implements ILinkEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsLinkEntity(),
		new MineUserLinkEntity(),
		new HitachiAdminLinkEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Link Id'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Link Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public linkId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Link Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Signal Count'] off begin
	@Validators.Integer()
	@observable
	@attribute()
	@CRUD({
		name: 'Signal Count',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public signalCount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Signal Count'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Speed'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Max Speed',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maxSpeed: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Constant speed'] off begin
	@Validators.Numeric()
	@observable
	@attribute()
	@CRUD({
		name: 'Constant speed',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public constantSpeed: number;
	// % protected region % [Modify props to the crud options here for attribute 'Constant speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Is Imported',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isImported: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Imported'] end

	// % protected region % [Modify props to the crud options here for attribute 'State'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'State',
		displayType: 'enum-combobox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.mapObjectStateOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.mapObjectStateOptions),
		displayFunction: (attr: Enums.mapObjectState) => Enums.mapObjectStateOptions[attr],
	})
	public state: Enums.mapObjectState;
	// % protected region % [Modify props to the crud options here for attribute 'State'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hook To Area Entry'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Hook To Area Entry',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hookToAreaEntry: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hook To Area Entry'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hook To Area Exit'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'Hook To Area Exit',
		displayType: 'checkbox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hookToAreaExit: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hook To Area Exit'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Default Speed'] off begin
	/**
	 * Whether default speed is used
	 */
	@observable
	@attribute()
	@CRUD({
		name: 'Is Default Speed',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isDefaultSpeed: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Default Speed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Start Sublink'] off begin
	@Validators.Uuid()
	@observable
	@attribute()
	@CRUD({
		name: 'Start Sublink',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public startSublink: string;
	// % protected region % [Modify props to the crud options here for attribute 'Start Sublink'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] off begin
		name: 'Import Version',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.ImportVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Import Version'] end
	})
	public importVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public importVersion: Models.ImportVersionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Segment'] off begin
		name: 'Segment',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.SegmentEntity,
		// % protected region % [Modify props to the crud options here for reference 'Segment'] end
	})
	public segmentId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public segment: Models.SegmentEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] off begin
		name: 'Map Object Errorss',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.MapObjectErrorsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectErrorss',
			oppositeEntity: () => Models.MapObjectErrorsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Errors'] end
	})
	public mapObjectErrorss: Models.MapObjectErrorsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] off begin
		name: 'Map Object Warningss',
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.MapObjectWarningsEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'mapObjectWarningss',
			oppositeEntity: () => Models.MapObjectWarningsEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Map Object Warnings'] end
	})
	public mapObjectWarningss: Models.MapObjectWarningsEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Signal Sets'] off begin
		name: 'Signal Setss',
		displayType: 'reference-multicombobox',
		order: 150,
		referenceTypeFunc: () => Models.SignalSetEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'signalSetss',
			oppositeEntity: () => Models.SignalSetEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Signal Sets'] end
	})
	public signalSetss: Models.SignalSetEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Sublinks'] off begin
		name: 'Sublinkss',
		displayType: 'reference-multicombobox',
		order: 160,
		referenceTypeFunc: () => Models.SublinkEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'sublinkss',
			oppositeEntity: () => Models.SublinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Sublinks'] end
	})
	public sublinkss: Models.SublinkEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Link To'] off begin
		name: 'Link To',
		displayType: 'reference-multicombobox',
		order: 170,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.LinkFromLinkTo,
		optionEqualFunc: makeJoinEqualsFunc('linkToId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'linkEntity',
			oppositeEntityName: 'linkEntity',
			relationName: 'linkFrom',
			relationOppositeName: 'linkTo',
			entity: () => Models.LinkEntity,
			joinEntity: () => Models.LinkFromLinkTo,
			oppositeEntity: () => Models.LinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Link To'] end
	})
	public linkTos: Models.LinkFromLinkTo[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Link From'] off begin
		name: 'Link From',
		displayType: 'reference-multicombobox',
		order: 180,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.LinkFromLinkTo,
		optionEqualFunc: makeJoinEqualsFunc('linkFromId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'linkEntity',
			oppositeEntityName: 'linkEntity',
			relationName: 'linkTo',
			relationOppositeName: 'linkFrom',
			entity: () => Models.LinkEntity,
			joinEntity: () => Models.LinkFromLinkTo,
			oppositeEntity: () => Models.LinkEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Link From'] end
	})
	public linkFroms: Models.LinkFromLinkTo[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ILinkEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ILinkEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.linkId !== undefined) {
				this.linkId = attributes.linkId;
			}
			if (attributes.signalCount !== undefined) {
				this.signalCount = attributes.signalCount;
			}
			if (attributes.maxSpeed !== undefined) {
				this.maxSpeed = attributes.maxSpeed;
			}
			if (attributes.constantSpeed !== undefined) {
				this.constantSpeed = attributes.constantSpeed;
			}
			if (attributes.isImported !== undefined) {
				this.isImported = attributes.isImported;
			}
			if (attributes.state !== undefined) {
				this.state = attributes.state;
			}
			if (attributes.hookToAreaEntry !== undefined) {
				this.hookToAreaEntry = attributes.hookToAreaEntry;
			}
			if (attributes.hookToAreaExit !== undefined) {
				this.hookToAreaExit = attributes.hookToAreaExit;
			}
			if (attributes.isDefaultSpeed !== undefined) {
				this.isDefaultSpeed = attributes.isDefaultSpeed;
			}
			if (attributes.startSublink !== undefined) {
				this.startSublink = attributes.startSublink;
			}
			if (attributes.importVersionId !== undefined) {
				this.importVersionId = attributes.importVersionId;
			}
			if (attributes.importVersion !== undefined) {
				if (attributes.importVersion === null) {
					this.importVersion = attributes.importVersion;
				} else if (attributes.importVersion instanceof Models.ImportVersionEntity) {
					this.importVersion = attributes.importVersion;
					this.importVersionId = attributes.importVersion.id;
				} else {
					this.importVersion = new Models.ImportVersionEntity(attributes.importVersion);
					this.importVersionId = this.importVersion.id;
				}
			}
			if (attributes.segmentId !== undefined) {
				this.segmentId = attributes.segmentId;
			}
			if (attributes.segment !== undefined) {
				if (attributes.segment === null) {
					this.segment = attributes.segment;
				} else if (attributes.segment instanceof Models.SegmentEntity) {
					this.segment = attributes.segment;
					this.segmentId = attributes.segment.id;
				} else {
					this.segment = new Models.SegmentEntity(attributes.segment);
					this.segmentId = this.segment.id;
				}
			}
			if (attributes.mapObjectErrorss !== undefined && Array.isArray(attributes.mapObjectErrorss)) {
				for (const model of attributes.mapObjectErrorss) {
					if (model instanceof Models.MapObjectErrorsEntity) {
						this.mapObjectErrorss.push(model);
					} else {
						this.mapObjectErrorss.push(new Models.MapObjectErrorsEntity(model));
					}
				}
			}
			if (attributes.mapObjectWarningss !== undefined && Array.isArray(attributes.mapObjectWarningss)) {
				for (const model of attributes.mapObjectWarningss) {
					if (model instanceof Models.MapObjectWarningsEntity) {
						this.mapObjectWarningss.push(model);
					} else {
						this.mapObjectWarningss.push(new Models.MapObjectWarningsEntity(model));
					}
				}
			}
			if (attributes.signalSetss !== undefined && Array.isArray(attributes.signalSetss)) {
				for (const model of attributes.signalSetss) {
					if (model instanceof Models.SignalSetEntity) {
						this.signalSetss.push(model);
					} else {
						this.signalSetss.push(new Models.SignalSetEntity(model));
					}
				}
			}
			if (attributes.sublinkss !== undefined && Array.isArray(attributes.sublinkss)) {
				for (const model of attributes.sublinkss) {
					if (model instanceof Models.SublinkEntity) {
						this.sublinkss.push(model);
					} else {
						this.sublinkss.push(new Models.SublinkEntity(model));
					}
				}

				// If the first sublink has a previousSublinkId, we need to sort the sublinks
				if (this.state !== "DELETED" && this.sublinkss.length > 0 && this.sublinkss[0].previousSublinkId !== undefined) {
					this.sublinkss = this.sublinkSort(this.sublinkss);
				}
			}
			if (attributes.linkTos !== undefined && Array.isArray(attributes.linkTos)) {
				for (const model of attributes.linkTos) {
					if (model instanceof Models.LinkFromLinkTo) {
						this.linkTos.push(model);
					} else {
						this.linkTos.push(new Models.LinkFromLinkTo(model));
					}
				}
			}
			if (attributes.linkFroms !== undefined && Array.isArray(attributes.linkFroms)) {
				for (const model of attributes.linkFroms) {
					if (model instanceof Models.LinkFromLinkTo) {
						this.linkFroms.push(model);
					} else {
						this.linkFroms.push(new Models.LinkFromLinkTo(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		linkTos {
			${Models.LinkFromLinkTo.getAttributes().join('\n')}
			linkTo {
				${Models.LinkEntity.getAttributes().join('\n')}
			}
		}
		linkFroms {
			${Models.LinkFromLinkTo.getAttributes().join('\n')}
			linkFrom {
				${Models.LinkEntity.getAttributes().join('\n')}
			}
		}
		importVersion {
			${Models.ImportVersionEntity.getAttributes().join('\n')}
			${Models.ImportVersionEntity.getFiles().map(f => f.name).join('\n')}
		}
		segment {
			${Models.SegmentEntity.getAttributes().join('\n')}
		}
		mapObjectErrorss {
			${Models.MapObjectErrorsEntity.getAttributes().join('\n')}
		}
		mapObjectWarningss {
			${Models.MapObjectWarningsEntity.getAttributes().join('\n')}
		}
		signalSetss {
			${Models.SignalSetEntity.getAttributes().join('\n')}
		}
		sublinkss {
			${Models.SublinkEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			linkTos: {},
			linkFroms: {},
			mapObjectErrorss: {},
			mapObjectWarningss: {},
			signalSetss: {},
			sublinkss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'mapObjectErrorss',
							'mapObjectWarningss',
							'signalSetss',
							'sublinkss',
							'linkTos',
							'linkFroms',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.linkId.toString();
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public getDistance() {
		const nodes: NodeEntity[] = this.getNodes();

		let totalLength = 0;
		for (let i = 1; i < nodes.length; i++) {
			const previousNode = nodes[i - 1];
			const currentNode = nodes[i];

			const x = currentNode.northing - previousNode.northing;
			const y = currentNode.easting - previousNode.easting;
			const distance = Math.sqrt(x * x + y * y);

			totalLength += distance;
		}

		return totalLength;
	}

	public nextLinks(): LinkEntity[] {
		return this.linkTos.map(x => store.mapStore.getEntity(x.linkToId, LinkEntity));
	}

	public previousLinks(): LinkEntity[] {
		return this.linkFroms.map(x => store.mapStore.getEntity(x.linkFromId, LinkEntity));
	}

	public firstSublink(useStore: boolean = true): SublinkEntity | undefined {
		const firstSublink = store.mapStore.getFirstSublinkForLink(this.getModelId());

		if (!useStore || firstSublink == undefined) {
			return this.getSublinks().find(x => x.isFirstSublink());
		}

		return firstSublink;
	}

	public lastSublink(): SublinkEntity | undefined {
		// Find the sublink that is not referenced by another sublink's previousSublinkId field
		const lastSublink = this.getSublinks()
			.find(sublink => sublink.isLastSublink());

		// This is a broken link if this can not be found
		if (!lastSublink) {
			throw new Error('Unable to find last sublink');
		}

		return lastSublink;
	}

	public firstNode(): NodeEntity | undefined {
		return store.mapStore.getFirstNodeForLink(this.getModelId());
	}

	public lastNode(): NodeEntity | undefined {
		return this.lastSublink()?.getLastNode();
	}

	public getNodes(): NodeEntity[] {
		const result: NodeEntity[] = [];

		const finalNode = this.lastNode();

		if (!finalNode) {
			return [];
		}

		let node = this.firstNode();
		while (node !== undefined && node.getModelId() !== finalNode?.getModelId()) {
			result.push(node);

			const nodes = node.getNextNodeAcrossConnectivities();
			if (nodes.length !== 1) {
				break;
			}

			[node] = nodes;
		}

		result.push(finalNode);

		return result;
	}

	public validateRefs() {
		this.getSublinks().forEach(x => {
			x.validateRefs();
			x.getNodes().forEach(y => y.validateRefs());
		});
	}

	public getSublinks(): SublinkEntity[] {
		if (!!this.sublinkss) {
			return this.sublinkss;
		}

		const result = [];

		let sublink = this.firstSublink();
		while (sublink !== undefined) {
			result.push(sublink);
			sublink = sublink.getNextSublink();
		}

		// If there are no sublinks found in the mapStore, fallback to returning the sublinks assigned to this entity
		if (result.length === 0) {
			return this.sublinkss;
		}

		return result;
	}

	public addNextLinks(links: string[]) {
		links.forEach(link => this.addNextLink(link));
	}

	public addPreviousLinks(links: string[]) {
		links.forEach(link => this.addPreviousLink(link));
	}

	public addNextLink(linkRef: string | LinkEntity) {
		const link = store.mapStore.getEntity(linkRef, LinkEntity);

		if (!link) {
			return;
		}

		if (this.linkTos.find(x => x.linkToId === link.getModelId())) {
			return;
		}

		const nextLink = store.mapStore.markEntityToUpdate(link);
		const currentLink = store.mapStore.markEntityToUpdate(this);

		const connectivity = new LinkFromLinkTo({
			id: uuid.v4(),
			linkToId: nextLink?.getModelId(),
			linkFromId: this.getModelId(),
			linkTo: nextLink,
			linkFrom: this,
		});

		currentLink.linkTos.push(connectivity);
		// remove existing same connectivities
		nextLink.linkFroms = nextLink.linkFroms.filter(s => !(s.linkToId == connectivity.linkToId && s.linkFromId == connectivity.linkFromId));
		nextLink.linkFroms.push(connectivity);

		store.mapStore.performUpdateOfEntity(link);
		store.mapStore.performUpdateOfEntity(currentLink);
	}

	public addPreviousLink(linkRef: string | LinkEntity) {
		const link = store.mapStore.getEntity(linkRef, LinkEntity);

		if (!link) {
			throw new Error('Unable to find link reference');
		}

		if (this.linkFroms.find(x => x.linkFromId === link.getModelId())) {
			return;
		}

		const previousLink = store.mapStore.markEntityToUpdate(link);
		const currentLink = store.mapStore.markEntityToUpdate(link);

		const connectivity = new LinkFromLinkTo({
			id: uuid.v4(),
			linkToId: this.getModelId(),
			linkTo: this,
			linkFromId: previousLink.getModelId(),
			linkFrom: previousLink,
		});

		// remove existing same connectivities
		previousLink.linkTos = previousLink.linkTos.filter(s => !(s.linkToId == connectivity.linkToId && s.linkFromId == connectivity.linkFromId));
		previousLink.linkTos.push(connectivity);
		this.linkFroms.push(connectivity);

		store.mapStore.performUpdateOfEntity(previousLink);
		store.mapStore.performUpdateOfEntity(currentLink);
	}

	public removePreviousLink(linkRef: string | LinkEntity): LinkFromLinkTo[] {
		const link = store.mapStore.getEntity(linkRef, LinkEntity);

		if (!link) {
			throw new Error('Unable to find link reference');
		}

		const currentLink = store.mapStore.markEntityToUpdate(this);
		const previousLink = store.mapStore.markEntityToUpdate(link);

		const linksToRemove = currentLink.linkFroms.filter(x => x.linkFromId === previousLink.getModelId());

		currentLink.linkFroms = currentLink.linkFroms.filter(x => linksToRemove.find(y => x.id === y.id) === undefined);
		previousLink.linkTos = previousLink.linkTos.filter(x => linksToRemove.find(y => x.id === y.id) === undefined);

		store.mapStore.performUpdateOfEntity(currentLink);
		store.mapStore.performUpdateOfEntity(previousLink);

		return linksToRemove;
	}

	public removeNextLink(linkRef: string | LinkEntity) {
		const link = store.mapStore.getEntity(linkRef, LinkEntity);

		if (!link) {
			throw new Error('Unable to find link reference');
		}

		const currentLink = store.mapStore.markEntityToUpdate(this);
		const nextLink = store.mapStore.markEntityToUpdate(link);

		const linksToRemove = currentLink.linkTos.filter(x => x.linkToId === link.getModelId());

		currentLink.linkTos = currentLink.linkTos.filter(x => linksToRemove.find(y => x.id === y.id) === undefined);
		nextLink.linkFroms = nextLink.linkFroms.filter(x => linksToRemove.find(y => x.id === y.id) === undefined);

		store.mapStore.performUpdateOfEntity(currentLink);
		store.mapStore.performUpdateOfEntity(nextLink);

		return linksToRemove;
	}

	public addTurnSignal(turnSignal: SignalSetEntity) {
		const currentLink = store.mapStore.markEntityToUpdate(this);

		TurnSignalHelper.addSignalToLinkEntity(turnSignal, currentLink);

		store.mapStore.performUpdateOfEntity(currentLink);
	}

	public removeTurnSignal(turnSignal: SignalSetEntity) {
		const currentLink = store.mapStore.markEntityToUpdate(this);

		currentLink.signalSetss = currentLink.signalSetss.filter(x => x.id !== turnSignal.id);

		store.mapStore.performUpdateOfEntity(currentLink);
	}

	public orderSublinksAndNodes(skipValidate: boolean = false) {
		this.sublinkss = this.getSublinks();
		this.sublinkss.forEach(sl => {
			sl.nodess = sl.getNodes();
		});
		if (!skipValidate) {
			this.validateStructure('orderSublinksAndNodes');
		}
	}

	public resetSublinkAndNodeRefs(includeSublink?: boolean) {
		// Remove refs within entity to avoid data being overwritten when assigning in previousSublinkId or previousNodeId attrs
		// This issue occurs when the id is updated, but the ref is not (the ref data overwrites the new id value)
		this.sublinkss.forEach(sl => {
			(sl.previousSublink as any) = undefined;
			(sl.nextSublink as any) = undefined;
			sl.nodess.forEach(n => {
				(n.previousNode as any) = undefined;
				(n.nextNode as any) =  undefined;
				if (includeSublink) {
					(n.sublink as any) = undefined;
				}
			});
		});
	}

	public resetLinkRefs() {
		this.sublinkss.forEach(s => {
			(s.link as any) =  undefined;
		});
	}

	/* *******************
	 * Validation - Error
	 * ****************** */
	public getErrors() {
		if (!this.mapObjectErrorss) {
			this.mapObjectErrorss = [];
		}
		return this.mapObjectErrorss;
	}

	public addErrors(errors: string[]) {
		errors.map(error => this.addError(error));
	}

	public addError(error: string): boolean {
		if (!this.hasError(error)) {
			const newError = new MapObjectErrorsEntity({
				errorMessage: error,
				linkId: this.getModelId(),
			});
			this.getErrors().push(newError);

			return true;
		}

		return false;
	}

	public resetAndAddNewErrors(errors: string[]) {
		this.resetError();
		this.addErrors(errors);
	}

	public removeError(error: string): boolean {
		const errorToDelete = this.getErrors().findIndex(x => x.errorMessage === error);
		if (errorToDelete !== -1) {
			this.getErrors().splice(errorToDelete, 1);
			return true;
		}

		return false;
	}

	public resetError() {
		this.mapObjectErrorss = [];
	}

	public hasError(error: string): boolean {
		return this.getErrors().find(x => x.errorMessage === error) !== undefined;
	}

	public getErrorCount() {
		return this.getErrors().length;
	}

	public sortSublinks() {
		this.sublinkss = this.sublinkSort(this.getSublinks());
	}

	private sublinkSort(sublinks: SublinkEntity[]) {
		return this.sortEntityList(sublinks, 'previousSublinkId');
	}

	public sortLink() {
		this.sortSublinks();
		this.sublinkss.forEach(s => s.sortNodes());
	}

	/* *******************
	 * Validation - Warning
	 * ****************** */
	public getWarnings() {
		if (!this.mapObjectWarningss) {
			this.mapObjectWarningss = [];
		}
		return this.mapObjectWarningss;
	}

	public addWarnings(warnings: string[]) {
		warnings.map(warning => this.addWarning(warning));
	}

	public addWarning(warning: string): boolean {
		if (!this.hasWarning(warning)) {
			const newWarning = new MapObjectWarningsEntity({
				warningMessage: warning,
				sublinkId: this.getModelId(),
			});
			this.getWarnings().push(newWarning);

			return true;
		}

		return false;
	}

	public resetAndAddNewWarnings(warnings: string[]) {
		this.resetWarnings();
		this.addWarnings(warnings);
	}

	public removeWarning(warning: string): boolean {
		const warningToDelete = this.getWarnings().findIndex(x => x.warningMessage === warning);
		if (warningToDelete !== -1) {
			this.getWarnings().splice(warningToDelete, 1);
			return true;
		}

		return false;
	}

	public resetWarnings() {
		this.mapObjectWarningss = [];
	}

	public hasWarning(warning: string): boolean {
		return this.getWarnings().find(x => x.warningMessage === warning) !== undefined;
	}

	public getWarningCount() {
		return this.getWarnings().length;
	}	

	public validateStructure(out: string) {
		// const { assert } = console;
		
		console.log(`validateStructure: ${out} id ${this.id}`);
		this.validateRefs();
		const sublinkList = this.getSublinks();
		function assert(isPass: boolean, msg?: string) {
			if (!isPass) {
				console.log(`Link failed: ${msg}`);
			}
		}

		assert(sublinkList.length === this.sublinkss.length, `Sublink lengths do not match (${sublinkList.length} != ${this.sublinkss.length})`);

		sublinkList.forEach((sublink, sublinkIndex, sublinks) => {
			if (sublinkIndex === 0) {
				assert(!sublink.previousSublinkId,
					`Sublink ${sublink.sublinkId} should not have a previousSublinkId. 
					Found ${sublink.previousSublinkId}`);
			} else {
				assert(sublink.previousSublinkId === sublinks[sublinkIndex - 1].getModelId(), `circular sublink ${sublink.previousSublinkId}`);
			}
			assert(sublink.linkId === this.getModelId(),
				`Sublink ${sublink.sublinkId} does not correctly reference link ${this.linkId}. 
				Found '${sublink.linkId}'`);

			const sublinkNodesList = sublink.getNodes();

			assert(sublink.nodess.length === sublinkNodesList.length,
				`Node lengths do not match for sublink ${sublink.sublinkId}`);

			sublinkNodesList.forEach((node, nodeIndex, nodes) => {
				assert(node.sublinkId === sublink.getModelId(),
					`Node ${node.nodeId} does not correctly reference the sublink. 
					Referencing ${node.sublinkId} and attached to ${sublink.getModelId()}`);
				assert(node.sublinkIdNumber === sublink.sublinkId,
					`Node ${node.nodeId} does not correctly reference sublink id number. 
					Referencing ${node.sublinkIdNumber} and attached to ${sublink.sublinkId}`);
				assert(node.linkIdNumber === this.linkId,
					`Node ${node.nodeId} does not correctly referencing link. 
					Referencing ${node.linkIdNumber} and attached to ${this.linkId}`);

				if (nodeIndex === 0) {
					assert(!node.previousNodeId, `Node ${node.nodeId} should not have a previousId`);
				} else {
					assert(node.previousNodeId === nodes[nodeIndex - 1].getModelId(),
						`Node ${node.nodeId} should have a previousId to ${nodes[nodeIndex - 1].nodeId}`);
				}
			});
		});
	}

	public setMapObjectState(state: mapObjectState) {
		// Ignore the case where this is already a new object (it doesn't matter if it is modified after, it's still new)
		if (this.state === 'NEW_OBJECT' && state === 'MODIFIED') {
			return;
		}

		this.state = state;
	}
	
	private  getObjectStateFromImportStatus(isImported: boolean) {
		return isImported ? 'MODIFIED' : 'NEW_OBJECT';
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(LinkEntity.prototype, 'created');
CRUD(modifiedAttr)(LinkEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
