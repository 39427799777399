/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import * as React from 'react';
import minLogo from 'Assets/images/hitachi-200x100.png';
import If from './Components/If/If';
import displayComingSoonToast from "../Util/ComingSoon";
// % protected region % [Add any extra imports here] end

// eslint-disable-next-line @typescript-eslint/no-unused-vars,import/prefer-default-export
export function getFrontendNavLinks(pageProps: RouteComponentProps): ILink[][] {
	// % protected region % [Add any logic before displaying page links here] off begin
	// % protected region % [Add any logic before displaying page links here] end
	return [
		[
			// % protected region % [Customise top nav section here] on begin
			{
				label: (
					<div>
						<If condition={!!store.mineSiteLogo}>
							<img src={store.mineSiteLogo} />
						</If>
						<div>
							{store.mineSite ?? 'Map Authoring Tool'}
						</div>
					</div>
				),
				path: '/',
				className: 'organisation-logo',
			},
			// % protected region % [Customise top nav section here] end
		],
		[
			// % protected region % [Customise middle nav section here] on begin
			{ label: 'Maps', path: '/maps' },
			{ label: 'Team', path: '/team' },
			{
				label: 'Settings',
				path: '/settings',
				shouldDisplay: () => store.isAHSMineAdmin,
			},
			{ label: 'Help', path: '/help' },
			// % protected region % [Customise middle nav section here] end
		],
		[
			// % protected region % [Customise bottom nav section here] on begin
			{ label: 'Logout', path: '/logout', shouldDisplay: () => store.loggedIn },
			{ label: (<img src={minLogo} alt="Hitachi" />), path: '/', className: 'hitachi-logo' },
			// % protected region % [Customise bottom nav section here] end
		],
	];
}
