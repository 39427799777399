import React, { useEffect, useState } from 'react';
import { observable, runInAction } from 'mobx';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { LinkFromLinkTo, MapToolParamEntity } from '../../../Models/Entities';
import LinkEntity from '../../../Models/Entities/LinkEntity';
import InputWrapper, { InputType } from '../../Components/Inputs/InputWrapper';
import DeleteConnectivityCommand from "../ChangeTracker/ChangeTypes/DeleteConnectivityCommand";
import { SetLinkSpeedCommand } from "../ChangeTracker/ChangeTypes/SetLinkSpeedCommand";
import CollapsibleProperty from '../CollapsibleProperty';
import MapController from '../Map/MapController';
import LinkOperationsHelper from '../Map/MapStateHandlerHelpers/LinkOperationsHelper';
import PathToolHelper from '../Map/MapStateHandlerHelpers/PathToolHelper';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import InputField from "./PropertiesPanelComponents/InputField";
import { convertToFixed } from './PropertiesSidePanel';
import {AddConnectivity, RenderConnectivityTable, validateConnectivity} from "./AddConnectivity";
import { AddTurnSignal, RenderTurnSignalTable } from "./AddTurnSignal";

export interface DerivedProperties {
    sublinksCount: number,
    linkLength: string,
    maxSpeed: number,
    minSpeed: number,
    rampStartSpeed?: number,
    rampEndSpeed?: number,
    constantSpeed?: number,
    turnSignalLength?: number,
}

/**
 * Render properties side panel for a selected link.
 * Link properties panel can be used to view link information and edit link connectivities and speed.
 * @param props
 * - entity: LinkEntity
 * - mapParams: MapToolParamEntity used for getting link restrictions such as min or max speed.
 * - map: MapController used for getting a lookup table
 * @constructor
 */
// eslint-disable-next-line max-len
export default function LinkProperties({ entity, mapParams, map } : { entity: LinkEntity, mapParams: MapToolParamEntity, map: MapController }) {
    let link = observable(entity);

    const initialState = () => {
        return {
            sublinksCount: 0,
            linkLength: '0',
            maxSpeed: 0,
            minSpeed: 0,
            constantSpeed: undefined,
            rampMinSpeed: undefined,
            rampMaxSpeed: undefined,
            turnSignalLength: calculateTurnSignalLength(),
        }}

    const [derivedProperties, setDerivedProperties] = useState<DerivedProperties>(() => initialState());
    const [displayConstantSpeed, setDisplayConstantSpeed] = useState(false);
    const [displayAdvanced, setDisplayAdvanced] = useState(false);
    const [displayAddTurnSignal, setDisplayAddTurnSignal] = useState(false);
    const [previousLinks, setPreviousLinks] = useState(link.previousLinks());
    const [nextLinks, setNextLinks] = useState(link.nextLinks());
    const [turnSignal, setTurnSignal] = useState(link.signalSetss.length > 0 ? link.signalSetss[0] : undefined);
    const [validLinkLengthForSignal, setValidLinkLengthForSignal] = useState(link.getDistance() <= 100 ? link.getDistance() : 100);

    const action = 'edit';

    function isConstantSpeed() {
        const result = link.isDefaultSpeed === false
            && !!link.constantSpeed; // NOT undefined && NOT null && NOT zero
        setDisplayConstantSpeed(result);
        return result;
    }

    useEffect(() => {
        setDerivedProperties({
            sublinksCount: link.sublinkss.length,
            linkLength: getLinkLength(),
            maxSpeed: calculateMaxSpeed(),
            minSpeed: calculateMinSpeed(),
            rampStartSpeed: undefined,
            rampEndSpeed: undefined,
            constantSpeed: link.constantSpeed,
            turnSignalLength: calculateTurnSignalLength(),
        });
        setDisplayConstantSpeed(isConstantSpeed());
        setPreviousLinks(link.previousLinks());
        setNextLinks(link.nextLinks());
        setTurnSignal(link.signalSetss.length > 0 ? link.signalSetss[0] : undefined);
        return () => {
            if (!link.constantSpeed) {
                // removed to fix undo/redo of edit link
                // runInAction(() => {
                // 	link.isdefaultspeed = true;
                // });
                setDisplayConstantSpeed(false);
            }
        };
    }, [entity]);

    function getLinkLength() {
        return link.getDistance().toFixed(2);
    }

    function calculateMaxSpeed() {
        let maxSpeed = Number.NEGATIVE_INFINITY;
        let negative = false;
        link.sublinkss.forEach(s => s.nodess.forEach(n => {
            if (n.speed !== 0 && Math.abs(n.speed) > maxSpeed) {
                maxSpeed = Math.abs(n.speed);
                negative = n.speed < 0;
            }
        }));

        // If the speeds are equal, but have different signs (positive and negative), prioritize displaying the positive value
        if (negative) {
            const hasPositiveEqualValue = link.sublinkss.some(s => s.nodess.some(n => maxSpeed === Math.abs(n.speed) && n.speed > 0));
            negative = hasPositiveEqualValue? !negative : negative;
        }

        return maxSpeed * (negative ? -1 : 1);
    }

    function calculateMinSpeed() {
        let minSpeed = Number.POSITIVE_INFINITY;
        let negative = false;
        link.sublinkss.forEach(s => s.nodess.forEach(n => {
            if (n.speed !== 0 && Math.abs(n.speed) < minSpeed) {
                minSpeed = Math.abs(n.speed);
                negative = n.speed < 0;
            }
        }));

        // If the speeds are equal, but have different signs (positive and negative), prioritize displaying the positive value
        if (negative) {
            const hasPositiveEqualValue = link.sublinkss.some(s => s.nodess.some(n => minSpeed === Math.abs(n.speed) && n.speed > 0));
            negative = hasPositiveEqualValue? !negative : negative;
        }

        return minSpeed * (negative ? -1 : 1);
    }

    function calculateTurnSignalLength(): number | undefined {
        if (link.signalSetss.length > 0 && !!link.signalSetss[0]) {
            const len = link.signalSetss[0].signalEnd - link.signalSetss[0].signalStart
            return len > 0 ? len : undefined;
        }
        return undefined;
    }

    /**
     * Validate link constant speed
     * @return true if valid
     * @return false otherwise
     * @constructor
     */
    function validateLinkConstantSpeed(value: number): string | undefined {
        if (value === 0 || !value) {
            return 'Please enter a valid speed';
        }

        const isReverse = value < 0;
        const speedType = isReverse ? ' reverse ' : ' ';

        // prevent changing the sign of link speed
        if ((link.constantSpeed > 0 && value < 0) || (link.constantSpeed < 0 && value > 0)) {
            return 'Speed sign of a link cannot be changed.';
        }

        const maxSpeedForward = mapParams.maxSpeedEmpty;
        const maxSpeedBackward = mapParams.maxSpeedBackward;
        const minSpeedForward = mapParams.minSpeedEmpty;
        const minSpeedBackward = -mapParams.minSpeedEmpty;

        const isConstantSpeedAboveMaximumLimit = value > maxSpeedForward
            || value < maxSpeedBackward;

        if (isConstantSpeedAboveMaximumLimit) {
            const maxSpeed = isReverse ? maxSpeedBackward : maxSpeedForward;
            return `Constant speed exceeds the maximum${speedType}speed ${maxSpeed} km/h`;
        }

        if (Math.abs(value) < Math.abs(minSpeedBackward) || Math.abs(value) < Math.abs(minSpeedForward)) {
            const minSpeed = isReverse ? minSpeedBackward : minSpeedForward;
            return `Constant speed is below the minimum${speedType}speed ${minSpeed} km/h`;
        }

        const firstSublink = map.getMapLookup().getFirstSublinkForLink(link.id);
        const firstNode = map.getMapLookup().getFirstNodeForSublink(firstSublink?.id ?? '');
        if (firstNode?.task === 'PARKING' && value < 0) {
            return 'Link speed must be positive';
        }

        // derivedProperties.constantSpeed and value here are not always number type
        // If focus and then blur twice (don't change value), they become string type,
        // which causes issue because constantSpeed at the backend only receive number type
        if (Number(derivedProperties.constantSpeed) === Number(value)) {
            console.log("No need to update constantSpeed");
        } else {
            // If input a new value, value is number type
            console.log("Update constantSpeed");
            derivedProperties.constantSpeed = value;

            link.constantSpeed = value;
            link.isDefaultSpeed = false;

            map.getTracker().addChange(SetLinkSpeedCommand.fromLink(link));
        }

        return undefined;
    }

    /**
     * Handle delete connectivity
     * @param linkToBeRemoved
     * @param currentLink
     * @param state
     */
    const handleDeleteConnectivity = (linkToBeRemoved: LinkEntity, currentLink: LinkEntity, state: string) => {
        console.log('LinkIds from handleDeleteConnectivity', currentLink.linkId, linkToBeRemoved.linkId);

        const _currentLink = LinkOperationsHelper.copyLink(currentLink);
        const _linkToBeRemoved = LinkOperationsHelper.copyLink(linkToBeRemoved);

        let deletedConnectivities: LinkFromLinkTo[] = [];
        if (state.toLowerCase() === 'previous') {
            deletedConnectivities = currentLink.removePreviousLink(linkToBeRemoved);
            setPreviousLinks(link.previousLinks());

            console.assert(deletedConnectivities.length === 1, 'In theory, there should be only one connectivity to be deleted at once.', deletedConnectivities);

            deletedConnectivities.forEach(x => map?.getTracker().addChange(new DeleteConnectivityCommand(x.linkFromId, x.linkToId)));
        }
        if (state.toLowerCase() === 'next') {
            deletedConnectivities = currentLink.removeNextLink(linkToBeRemoved);
            setNextLinks(link.nextLinks());

            console.assert(deletedConnectivities.length === 1, 'In theory, there should be only one connectivity to be deleted at once.', deletedConnectivities);

            deletedConnectivities.forEach(x => map?.getTracker().addChange(new DeleteConnectivityCommand(x.linkFromId, x.linkToId)));
        }
    };

    /**
     * Render set link speed tab
     * @constructor
     */
    function RenderSetLinkSpeed() {
        /*
         * Prevent setting a constant speed if a special node is in the middle of the link (not the first or last node)
         */
        const canSetConstantSpeed = (): boolean => !link.getNodes()
            .every((n, i, arr) => (n.task === 'HAULING' || i === 0 || i === arr.length - 1));

        return (
            <>
                <div className="link-speed">
                    <InputWrapper inputType={InputType.RADIO} label="Default Speed" className="extra-margin">
                        <input
                            type="radio"
                            onChange={() => {
                                runInAction(() => {
                                    link.isDefaultSpeed = true;
                                });
                                setDisplayConstantSpeed(!link.isDefaultSpeed);

                                map.getTracker().addChange(SetLinkSpeedCommand.fromLink(link));
                            }}
                            checked={!displayConstantSpeed}
                        />
                    </InputWrapper>
                    <InputWrapper inputType={InputType.RADIO} label="Constant">
                        <input
                            type="radio"
                            disabled={canSetConstantSpeed()}
                            onChange={() => {
                                runInAction(() => {
                                    link.isDefaultSpeed = false;
                                });
                                setDisplayConstantSpeed(!link.isDefaultSpeed);

                                map.getTracker().addChange(SetLinkSpeedCommand.fromLink(link));
                            }}
                            checked={displayConstantSpeed}
                        />
                    </InputWrapper>
                    {
                        displayConstantSpeed
                            ? (
                                <>
                                    <InputField
                                        key={`speed_${link.linkId}`}
                                        model={derivedProperties}
                                        label="Speed"
                                        modelProperty="constantSpeed"
                                        propertyUnit="km/h"
                                        isNumber
                                        renderDisplayValue={value => convertToFixed(value, 1)}
                                        onValidateInput={(value: any) => validateLinkConstantSpeed(value)}
                                    />
                                </>
                            ) : null
                    }
                </div>
            </>
        );
    }

    const _maxOrMinSpeed = (speedKey: string) => {
        const speed = derivedProperties[speedKey];
        const dataType = typeof speed;
        let newSpeed = '0.0';
        if (dataType === 'number') {
            newSpeed = speed.toFixed(1);
        } else if (dataType === 'string') {
            newSpeed = speed as any as string;
        }
        return {
            [speedKey]: newSpeed,
        }
    };

    const connectivityPlusButton = (
        <Button
            className="plus-btn no-background"
            icon={{ icon: 'plus', iconPos: 'icon-right' }}
            colors={Colors.White}
            display={Display.Text}
            onClick={() => setDisplayAdvanced(prevState => !prevState)}
        />
    );

    const signalPlusButton = (
        <Button
            className="plus-btn no-background"
            icon={{ icon: 'plus', iconPos: 'icon-right' }}
            colors={Colors.White}
            display={Display.Text}
            onClick={() => {
                let validSignals = link.signalSetss.map(s => s.signalStart >= 0);
                if(validSignals.length === 0) {
                    setDisplayAddTurnSignal(prevState => !prevState);
                }
            }}
        />
    );

    return (
        <>
            <h6>Link Properties</h6>
            <InputField model={link} label="ID" modelProperty="linkId" propertyUnit="" isReadOnly />
            <InputField
                model={derivedProperties}
                label="No. of Sublinks"
                modelProperty="sublinksCount"
                propertyUnit=""
                isReadOnly
            />
            <InputField model={derivedProperties} label="Length" modelProperty="linkLength" propertyUnit="m" isReadOnly />
            <InputField
                model={_maxOrMinSpeed("maxSpeed")}
                label="Max Speed"
                modelProperty="maxSpeed"
                propertyUnit="km/h"
                isNumber
                isReadOnly
            />
            <InputField
                model={_maxOrMinSpeed("minSpeed")}
                label="Min Speed"
                modelProperty="minSpeed"
                propertyUnit="km/h"
                isNumber
                isReadOnly
            />
            <div className="section-divider" />

            {displayAdvanced
                && <AddConnectivity
                    currentLink={link}
                    hidePanel={() => setDisplayAdvanced(false)}
                    map={map}
                    nextLinks={nextLinks}
                    previousLinks={previousLinks}
                    mapParams={mapParams}
                    link={link}
                    setNextLinks={setNextLinks}
                    setPreviousLinks={setPreviousLinks}
                />}

            <CollapsibleProperty
                className="connectivity"
                propertyTitle="Connectivity"
                displayProperty
                plusButton={connectivityPlusButton}
            >
                <RenderConnectivityTable
                    previousLinks={previousLinks}
                    validateConnectivity={validateConnectivity}
                    link={link}
                    action={action}
                    handleDeleteConnectivity={handleDeleteConnectivity}
                    nextLinks={nextLinks}
                    mapParams={mapParams}
                    map={map}
                    setPreviousLinks={setPreviousLinks}
                    setNextLinks={setNextLinks}
                />
            </CollapsibleProperty>

            <div className="section-divider" />

            {displayAddTurnSignal
                && <AddTurnSignal parentLink={link} hidePanel={() => setDisplayAddTurnSignal(false)}
                                  validLinkLengthForSignal={validLinkLengthForSignal} link={link} map={map} setTurnSignal={setTurnSignal}
                />}

            <CollapsibleProperty
                className="turn-signal"
                propertyTitle="Turn Signal"
                displayProperty
                plusButton={signalPlusButton}
            >
                <RenderTurnSignalTable
                    validLinkLengthForSignal={validLinkLengthForSignal}
                    turnSignal={turnSignal}
                    setTurnSignal={setTurnSignal}
                    link={link}
                    map={map}
                    derivedProperties={derivedProperties}
                    setDerivedProperties={setDerivedProperties}
                />
            </CollapsibleProperty>

            <div className="section-divider" />
            <CollapsibleProperty propertyTitle="Link Speed" displayProperty>
                {RenderSetLinkSpeed()}
            </CollapsibleProperty>
            <div className="section-divider" />
            <ErrorsAndWarnings mapObject={link} mapController={map} />
            <div className="section-divider" />
        </>
    );
}
